<template>
	<div class="container">
		<div class="w1200">
			<div class="nav_top">当前位置：<router-link :to="{path:'/'}">首页</router-link> > <router-link :to="{path:'/sweep'}">网上祭扫</router-link> > 烈士详情</div>	
			<div class="wapper">				
				<div class="page1 flex">
					<div class="header">
						<div>
							<img :src="detail.cover"/>
						</div>
					</div>
					<div class="infor">
						<p><span>{{detail.name}}</span>（{{detail.birthDeath}}）</p>
						<div class="jianjie">
							<div class="content scrollbar" v-html="detail.content">
							</div>
						</div>
					</div>
				</div>
				<div class="page2">
					<p class="num flex_a"><img src="@/assets/images/hua.png" alt="">您是第<span>{{detail.number}}</span>位祭奠英烈的网友</p>
					<div class="content">
						<ul class="flex">
							<li class="flex_a" v-for="(item,index) in detail.eulogyList" :key="index" @click="handleChangeFlag(index)">
								<img :src="item.checked ? xuanzhong : weixuan"/>{{item.title}}
							</li>
						</ul>
						<div class="text">{{text}}</div>
					</div>
					<div class="flower flex_a">
						<div class="flex_a" v-for="(item,index) in detail.flowerDtoList" :key="index" @click="handleChangeFlower(index)">
							<img :src="item.checked ? xuanzhong : weixuan"/>{{item.name}}
						</div>
						<span class="btn" @click="handleSend">发送</span>
					</div>
				</div>
				<div class="page3 flex">
					<div class="left">
						<h2>网友悼词：</h2>
							<ul>
								<vue-seamless-scroll :data="detail.onlineSweepTombsRecordDtoList" :class-option="defaultOption" >
									<li v-for="(item,index) in detail.onlineSweepTombsRecordDtoList" :key="index"><span>{{item.usersName}}</span>：{{item.eulogyStr}}</li>
								</vue-seamless-scroll>
							</ul>
					</div>
					<div class='right'>
						<ul>
							<li class="flex_a"  v-for="(item,index) in detail.flowerDtoList" :key="index">
								<img :src="item.cover"/>
								<div class="info">
									<p>{{item.name}}</p>
									<p><span>{{item.personTime}}/</span>人次</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!-- <p class="back"><el-button type="primary" @click="toBack">返回</el-button></p>  -->
			
		</div>

	</div>
</template>

<script>
	import urlobj from '@/api/baseurl.js'
	import vueSeamlessScroll from 'vue-seamless-scroll'
	export default {
		data() {
			return {
				id: '',
				text: '',//悼词
				weixuan: require('@/assets/images/weixuan_bai.png'),
				xuanzhong: require('@/assets/images/xuanzhong_bai.png'),
				detail: '',
				eulogyId: '',  //悼词id
				flowerId: '', //鲜花id
			}
		},
		components: {
			vueSeamlessScroll
		},
		computed: {
            defaultOption () {
                return {
                    step: 0.2, // 数值越大速度滚动越快
                    limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
                    hoverStop: false, // 是否开启鼠标悬停stop
                    direction: 1, // 0向下 1向上 2向左 3向右
                    openWatch: true, // 开启数据实时监控刷新dom
                    singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                    waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
                }
            }
 
        },
		created() {
			document.title = '网上祭扫'
			
		},
		mounted() {
			if(this.$route.query.id){
				this.id = this.$route.query.id
			}
			this.getDetail()
		
		},
		methods: {
			toBack() {
				this.$router.go(-1)
			},
			getDetail() {
				let formdata =new FormData();
				formdata.append('id', this.id)
				this.$http({
					url: `/martyrsApi/queryMartyrsDetail`,
					method: "POST",
					data: formdata,
				}).then((res) => {
					this.detail = res.data
					this.detail.eulogyList[0].checked = true
					this.detail.flowerDtoList[0].checked = true
					this.text = this.detail.eulogyList[0].content
					this.eulogyId = this.detail.eulogyList[0].id
					this.flowerId = this.detail.flowerDtoList[0].id

				});
			},
			handleSend() {
				// if(!localStorage.getItem('userInfo')){
				// 	this.$message.error('请先登录')
				// 	return
				// }
				let formdata =new FormData();
        if(localStorage.getItem('userInfo')){
          formdata.append('usersId', JSON.parse(localStorage.getItem('userInfo')).id)
        }
				formdata.append('eulogyId', this.eulogyId)
				formdata.append('flowerId', this.flowerId)
				formdata.append('martyrsId', this.detail.id)

				this.$http({
					url: `/martyrsApi/editSubmitSweepTombs`,
					method: "POST",
					data: formdata,
				}).then((res) => {
					if(res.state == 10200){
						this.$message.success('发送成功')
						this.getDetail()
					}
				});
				
			},
			handleChangeFlag(index) {
				this.detail.eulogyList.forEach(element => {
					element.checked = false
				});
				this.detail.eulogyList[index].checked = !this.detail.eulogyList[index].checked
				this.text = this.detail.eulogyList[index].content
				this.eulogyId = this.detail.eulogyList[index].id
				this.$forceUpdate()
			},
			handleChangeFlower(index) {
				this.detail.flowerDtoList.forEach(element => {
					element.checked = false
				});
				this.detail.flowerDtoList[index].checked = !this.detail.flowerDtoList[index].checked
				this.flowerId = this.detail.flowerDtoList[index].id
				this.$forceUpdate()
			}
			
		}
	}
</script>

<style lang="scss" scoped>
.container{
	.back{
		color: #FFF;
		text-align: center;
		margin: 30px 0;
	}
	.wapper{
		background: url('../../assets/images/jisaobeijing.png') no-repeat;
		padding: 60px;
		
		.page1{
			.header{
				width: 373px;
				height: 370px;
				background: url('../../assets/images/zhapiankuang.png') no-repeat;
				background-size: contain;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 60px;
				>div{
					border: 2px solid #A5343C;
					img{
						border: 10px solid #FFF;
						width: 215px;
						height: 260px;
					}
				}
			}
			.infor{
				flex: 1;
				color: #FFF;
				p{
					padding-left: 40px;
					font-size: 18px;
					span{
						font-size: 36px;
						font-weight: bold;
					}
				}
				.jianjie{
					width: 640px;
					height: 310px;
					background: url('../../assets/images/jianjiekuang.png') no-repeat;
					background-size: contain;
					color: #FFF;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 16px;
					.content{
						height: 230px;
						width: 85%;
						overflow-y: auto;
						img{
							max-width: 100%;
							margin-top: 10px;
						}
					}
				}
			}
		}
		.page2{
			color: #FFF;
			margin-top: 30px;
			.num{
				text-shadow: 0px 0px 13px #C4F0FF;
				font-size: 42px;
				font-weight: bold;
				img{
					width: 60px;
					height: 60px;
				}
				span{
					font-size: 46px;
					color: #E20000;
				}
			}
			.content{
				border: 1px solid #3357A9;
				border-radius: 4px;
				overflow: hidden;
				margin-top: 20px;
				ul{
					background: #3357A9;
					padding: 7px 20px;
					li{
						margin-right: 20px;
						cursor: pointer;
						img{
							margin-right: 10px;
						}
					}
				}
				.text{
					padding: 10px 20px;
					background: #162853;
				}

			}
			.flower{
				padding: 10px 20px;
				padding-right: 0;
				.flex_a{
					margin-right: 20px;
					cursor: pointer;
					img{
						margin-right: 10px;
					}
				}
				.btn{
					background: #3357A9;
					padding: 7px 10px;
					border-radius: 5px;
					margin-left: auto;
					cursor: pointer;
				}
			}
		}
		.page3{
			margin-top: 30px;
			.left{
				color: #FFF;
				width: 730px;
				background: rgba(22, 40, 83, 0.7);
				padding: 20px;
				border-radius: 10px;
				h2{
					font-size: 24px;
				}
				ul{
					height: 230px;
					overflow: hidden;
					margin-top: 20px;
					li{
						margin-bottom: 20px;
						span{
							color: #B69A5D;
						}
					}
				}


			}
			.right{
				color: #FFF;
				margin-left: auto;
				li{
					margin-bottom: 20px;
					&:last-of-type{
						margin: 0;
					}
				}
				img{
					margin-right: 10px;
					width: 66px;
					height: 66px;
				}
				.info{
					p{
						&:nth-of-type(1){
							margin-bottom: 15px;
							font-size: 18px;
						}
						&:nth-of-type(2){
							font-size: 16px;
							span{
								color: #76A0FF;
							}
						}
					}
				}
			}

		}
	}
		
		
}
	
</style>
